<template>
    <div class="pt-1 px-3">

        <div class="">
            <div @click="goBack()" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </div>
        </div>

        <h2 class="text-center text-white mt-2">Form Volunteer</h2>

        <form id="frm">
            <div class="d-flex mt-2">
                <div class="m-auto position-relative">
                    <img :src="url ? url : (input_data.shv_picture ? input_data.shv_picture : default_img)" id="user_preview" class="bg-white" 
                        style="border-radius: 10px;height:180px;width:180px;object-fit: cover;" />
                    <div @click="selectPicture()" class="position-absolute d-flex rounded-circle" style="bottom: 10px;right: 10px;background-color: #1B628C;padding:.5em">
                        <i class="bx bxs-camera m-auto" style="font-size: 18px;color: white;"></i>
                    </div>
                    <div id="form">
                        <input type="file" @change="onFileChange" name="shv_picture" id="shv_picture" class="d-none" accept="image/*" />
                    </div>
                </div>
            </div>
            <div class="d-flex mt-1">
                <div class="badge badge-danger text-white m-auto" v-if="(!url && !input_data.shv_picture) && isSubmit" 
                    style="margin-top: .5em;">Event picture not valid</div>
            </div>

            <div class="mt-2">
                <label class="h3 text-white">Event Name</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-captions"></i></span>
                    <input class="form-control" placeholder="Event Name" name="inp[shv_title]" v-model="input_data.shv_title" >
                </div>
                <div class="text-white" style="margin-top: .5em;">*Event name is atleast 2 character</div>
                <div class="badge badge-danger text-white" v-if="!valid.shv_title && isSubmit" style="margin-top: .5em;">Event name not valid, atleast 2 character</div>
            </div> 
            <div class="mt-2">
                <label class="h3 text-white">Cause</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-notepad"></i></span>
                    <input class="form-control" placeholder="Cause" name="inp[shv_cause]" v-model="input_data.shv_cause" >
                </div>
                <div class="text-white" style="margin-top: .5em;">*Event cause is atleast 2 character</div>
                <div class="badge badge-danger text-white" v-if="!valid.shv_cause && isSubmit" style="margin-top: .5em;">Event cause not valid, atleast 2 character</div>
            </div> 
            <div class="mt-2">
                <label class="h3 text-white">Location</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-map"></i></span>
                    <input class="form-control" placeholder="Location" name="inp[shv_location]" v-model="input_data.shv_location" >
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.shv_location && isSubmit" style="margin-top: .5em;">Event location not valid</div>
            </div> 
            <div class="mt-2">
                <label class="h3 text-white">Date</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-calendar"></i></span>
                    <input class="form-control" type="date" name="inp[shv_date]" v-model="input_data.shv_date" >
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.shv_date && isSubmit" style="margin-top: .5em;">Event date not valid</div>
            </div> 
            <div class="mt-2">
                <label class="h3 text-white">Time</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-time"></i></span>
                    <input class="form-control" type="time" name="inp[shv_time]" v-model="input_data.shv_time" >
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.shv_date && isSubmit" style="margin-top: .5em;">Event time not valid</div>
            </div>  
            <div class="mt-2">
                <label class="h3 text-white">Details</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-comment"></i></span>
                    <textarea class="form-control" name="inp[shv_details]" placeholder="Details" v-model="input_data.shv_details"></textarea>
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.shv_details && isSubmit" style="margin-top: .5em;">Details not valid, atleast 8 character</div>
            </div>
        </form>

        <div class="mt-3 w-100 d-flex flex-column justify-content-center" style="margin-bottom: 3em;gap:10px">
            <button class="btn text-white flex-fill" @click="onSubmit()"
                style="background-color: #187294;height:50px;font-size: 18px;">
                Save
            </button>
        </div>

    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

export default {
    computed: {
        user() {
            return store.state.auth.USER
        }, 
        valid(){
            return {
                shv_title : this.input_data.shv_title?.length > 2,
                shv_cause : this.input_data.shv_cause?.length > 2,  
                shv_location : this.input_data.shv_location?.length > 2,  
                shv_date : this.input_data.shv_date?.length > 0,  
                shv_time : this.input_data.shv_time?.length > 0,  
                shv_details : this.input_data.shv_details?.length > 7,
                picture: this.url || this.input_data.shv_picture
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    }, 
    async mounted() {
        if (this.id) {
          this.input_data = await store.dispatch('shelter/GetVolunteer', this.id)  
        }
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
        },
        selectPicture(){
            $('#shv_picture').click()
        },
        goBack() {
            this.$router.go(-1)
        },
        onSubmit() {
            this.isSubmit = true
            if(this.allValid){
                let formData = new FormData($('#frm')[0])
                formData.append('inp[shelter_id]', this.shelter_id)
                if(this.id){
                    formData.append('id', this.id)
                }
                store.dispatch('shelter/SaveVolunteer', formData).then(() => {
                    this.$router.push('/shelter/volunteer/'+this.shelter_id)
                })
            }
        }
    },
    data() {
        return {
            shelter_id: this.$route.params.shelter_id,
            id: this.$route.params.id,
            input_data: {},
            isSubmit: false,
            url: null,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>

<style>
.auth-page .btn {
    border-radius: 20px;
}

input:focus, textarea:focus {
    color: white !important;
}

.input-group span {
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    font-size: 24px;
    color: #1F9BCD;
    background-color: transparent;
}

.input-group input, .input-group textarea {
    margin-left: 0;
    padding-left: 0;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    height: 40px;
    color: white;
    background-color: transparent !important;
}
</style>